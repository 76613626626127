export const listService = [
  {
    id: 1,
    title: 'Gestión Comercial',
    title_en: 'Commercial Management',
    icon: require('../assets/service/icon-gestion.svg'),
    description: 'Asesoria Comercial.<br/>Desarrollo de negocios.',
    description_en: 'Business Development.<br/>Business Consulting.'
  },
  {
    id: 2,
    title: 'Back Office',
    title_en: 'Back Office',
    icon: require('../assets/service/icon-office.svg'),
    description: 'Permisos & Autorizaciones.</br>Administracion & Legal.',
    description_en: 'Permits & Autorizations.<br/>Administration. & Legal.',
  },
  {
    id: 3,
    title: 'Logistica',
    title_en: 'Logistics',
    icon: require('../assets/service/icon-quality.svg'),
    description: 'Gestión de Contratos, Servicios de Soporte.<br/>Procur y Logistica.',
    description_en: 'Support Services.<br/>Purchasing & Logistics.',
  },
  {
    id: 4,
    title: 'Calidad',
    title_en: 'QA/QC',
    icon: require('../assets/service/icon-quality.svg'),
    description: 'Inspecciones y Certificaciones.<br/>Procedimientos.<br/>Sistemas Integrados de Gestión.',
    description_en: 'Inspections & Certifications.<br/>Procedures.<br/>Integrated quality Management.',
  },
  {
    id: 5,
    title: 'Laboratorio y Medio Ambiente',
    title_en: 'Lab Analysis & Environmental',
    icon: require('../assets/service/icon-ambiente.svg'),
    description: 'Análisis Geoquímicos, Concentrados y Medio Ambientales.<br/>Auditoria y Asesorías.<br/>Patrones.',
    description_en: 'Geochemical, Concentrate and Environmental assays.<br/>Technical Audits & Consulting.<br/>Standards.',
  },
  {
    id: 6,
    title: 'Ingeniería',
    title_en: 'Engineering',
    icon: require('../assets/service/icon-engineering.svg'),
    description: 'Ingenieria Metalúrgica.<br/>Plantas de tratamiento de aguas y Efluentes.<br/>Instalaciones y transporte de líquidos peligrosos.<br/>Sistemas de mantenimiento.',
    description_en: 'Metalurgia Engineering.<br/>Water and effluent treatment plants.<br/>Hazardous Liquid Facilities & transportation.<br/>Maintenance Systems.',
  },
  {
    id: 7,
    title: 'Comunicaciones',
    title_en: 'Communication',
    icon: require('../assets/service/icon-community.svg'),
    description: 'Canales de Comunicación.<br/>Entrenamiento.',
    description_en: 'Communication Channels.<br/>Training.',
  },
  {
    id: 8,
    title: 'TI',
    title_en: 'IT',
    icon: require('../assets/service/icon-ti.svg'),
    description: 'Seguridad de Sistemas.<br/>Operaciones digitales.',
    description_en: 'Security Systems.<br/>Digital Operations.',
  },
]