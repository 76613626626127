<template>
  <div class="home-page">
    <div class="slider-section">
      <div class="container">
        <h2 class="wow animate__fadeInDown"  v-html="lang.home.slider.title"></h2>
      </div>
      <div class="bg-shadow">
        <button type="button" class="btn-scroll" @click="onGoTo('service')">
          <img src="../assets/icon-scroll.svg" alt="">
        </button>
      </div>
    </div>
    <div id="service" class="service-section">
      <div class="bg-black"></div>
      <div class="container">
        <div class="service">
          <div class="service__desktop">
            <div
              id="service_graph"
              class="service__graph"
              :class="{ active: activeGraph, showDetail: showDetail }"
            >
              <img class="gear-content" src="@/assets/icons/gear-mather.svg" />
              <div class="service__central">
                <img class="gear" src="@/assets/icons/icon-gear.svg" />
                <span><img class="logo" src="../assets/logo.png" /></span>
              </div>
              <div
                v-for="item in listService"
                :key="`service${item.id}`"
                :class="`service__point point_${item.id} ${activeGraph ? 'active': ''} ${focusService(item.id)}`"
                @click="onSelectService(item)"
              >
                <img class="icon-gear" :src="require(`@/assets/icons/gear-step${item.id}.svg`)" />
                <div class="b-center">
                  <img :src="item.icon" :alt="`Servicio - ${item.title}`" />
                  <span>{{ language==='es' ? item.title : item.title_en }}</span>
                </div>
              </div>
            </div>
            <div
              :class="`service__detail ${selectService ? 'detail_'+selectService.id : ''} ${showDetail ? 'showDetail': ''}`"
            >
              <template v-if="selectService">
                <img class="icon-gear" :src="require(`@/assets/icons/gear-step${selectService.id}.svg`)" />
                <div class="b-center">
                  <div class="t-center">
                    <h4>{{ language==='es' ? selectService.title : selectService.title_en }}</h4>
                    <div class="description" v-html="language==='es' ? selectService.description : selectService.description_en"></div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="service__mobile">
            <div class="row">
              <div
                class="col-xs-12 col-sm-6" 
                v-for="item in listService"
                :key="`service${item.id}`"
              >
                <div class="service__gear">
                  <div class="service__gear--item">
                    <div
                      :class="`service__gear--point point_${item.id} active`"
                    >
                      <img class="icon-gear" :src="require(`@/assets/icons/gear-step${item.id}.svg`)" />
                      <div class="b-center">
                        <img :src="item.icon" :alt="`Servicio - ${item.title}`" />
                      </div>
                    </div>
                    <div :class="`service__gear--description color_${item.id}`">
                      <h4>{{ language==='es' ? item.title : item.title_en }}</h4>
                      <div class="description" v-html="language==='es' ? item.description: item.description_en"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="digit" class="digit-section">
      <div class="container">
        <div class="digit wow animate__fadeInLeft">
          <div class="digit__wrapper" id="achievementsId">
            <div class="digit__item" >
              <div class="digit__inner">
                <img src="@/assets/icons/icon-professional.svg" />
                <span class="number">+<i-count-up
                  id="number1"
                  class="digits"
                  :delay="-1"
                  :end-val="25"
                  @ready="onReady"
                /></span>
                <span class="name">{{lang.home.digit.block1}}</span>
              </div>
            </div>
            <div class="digit__item">
              <div class="digit__inner">
                <img src="@/assets/icons/icon-country.svg" />
                <span class="number">+<i-count-up
                  id="number1"
                  class="digits"
                  :delay="-1"
                  :end-val="5"
                  @ready="onReady"
                /></span>
                <span class="name">{{lang.home.digit.block2}}</span>
              </div>
            </div>
            <div class="digit__item" >
              <div class="digit__inner">
                <img src="@/assets/icons/icon-client.svg" />
                <span class="number">+<i-count-up
                  id="number1"
                  class="digits"
                  :delay="-1"
                  :end-val="12"
                  @ready="onReady"
                /></span>
                <span class="name">{{lang.home.digit.block4}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="news" class="news-section">
      <div class="container">
        <div class="b-title">
          <h2 >{{lang.home.news.title}}</h2>
        </div>
        <div class="news wow animate__fadeInLeft">
          <div class="news__wrapper row">
            <div class="news__item col-xs-12 col-md-6" v-for="item in listNews" :key="item.id">
              <div class="news__inner" >
                <div class="news__img" :style="`background-image: url(/dailypacific/storage/app/public/${item.urlbanner})`">
                  <div class="news__info">
                    <div class="d-vertical">
                      <img class="news__info--img" src="@/assets/icons/icon-calendar.svg" />
                      <span class="news__info--date">{{item.updated_at | filterDateNew }}</span>
                      <!-- span class="news__info--location">Arequipa</span -->
                    </div>
                  </div>
                </div>
                <div class="news__text">
                  <h3 class="news__text--title">{{item.titulo}}</h3>
                  <span class="news__text--description" style="font-size:12px" v-html="item.keywords"></span>
                  <p class="news__text--description" v-html="item.descripcion"></p>
                  <div class="text-left">
                    <router-link :to="{ name: 'NewsDetailPage', params: { id: item.id } }">
                      <span class="icon-plus">+</span>
                      <span class="text">{{lang.home.news.btn_go}}</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="b-more">
              <router-link :to="{ name: 'NewsPage' }" class="btn-more">{{lang.home.news.btn_more}}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { listService } from '@/utils/listService';
import ICountUp from "vue-countup-v2";

export default {
  name: "HomePage",
  beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-console
    next(vm => {
      const hash = (to.hash || "").replace("#", "");
      if (hash.length) {
        const elem = document.getElementById(hash);
        setTimeout(() => {
          vm.$scrollTo(elem, 600);
        }, 300);
      }
    });
  },
  props: {
    news: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ICountUp,
  },
  data() {
    return {
      listService,
      activeGraph: false,
      showDetail: false,
      selectService: null
    }
  },
  computed: {
    listNews() {
      if (this.news.length > 4) {
        return this.news.slice(1, 5);
      }
      return this.news;
    }
  },
  filters: {
    textService(val) {
      return `${val.slice(0, 80)}...`; 
    },
    filterDateNew(val) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(val)
      return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }
  },
  watch: {
    $route(val) {
      if (val.hash) {
        const hash = (val.hash || "").replace("#", "");
        if (hash.length) {
          const elem = document.getElementById(hash);
          setTimeout(() => {
            this.$scrollTo(elem, 600);
          }, 300);
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    focusService(id) {
      if (this.selectService) {
        return this.selectService.id === id ? 'service--focus' : '';
      }
      return '';
    },
    onSelectService(service) {
      this.showDetail = true;
      this.selectService = service;
    },
    handleScroll() {
      const graphId = document.getElementById("service_graph");
      if (document.documentElement.scrollTop > this.offset(graphId).top - 150) {
        this.activeGraph = true;
      }
    },
    onGoTo(hash) {
      const elem = document.getElementById(hash);
      // eslint-disable-next-line no-console
      console.log(elem);
      setTimeout(() => {
        this.$scrollTo(elem, 600);
      }, 300);
    },
    offset(el) {
      const rect = el.getBoundingClientRect();
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    },
    onReady(instance) {
      const achievementsId = document.getElementById("achievementsId");
      window.addEventListener("scroll", () => {
        document.documentElement.scrollTop >
        this.offset(achievementsId).top - 350
          ? instance.start()
          : null;
      });
    },
  }
}
</script>